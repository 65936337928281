import styled from 'styled-components';


type Props = {
	hide: boolean;
}

const Container = styled.div<Props>`
	display: flex;
	cursor: pointer;
	align-items: center;
	justify-content: center;

	border: 2px solid #000000;
	border-radius: 3px;
	min-width: 20px;
	min-height: 20px;

	opacity: ${({ hide }) => hide ? 0 : 1};

	&[data-disabled='true'] {
		cursor: not-allowed;
	}
`;

export default Container;
